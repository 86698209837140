<template>
  <div class="flex flex-col flex-grow justify-center items-center">
    <h2 class="my-8 text-center text-xl font-medium">Launching app</h2>
    <Icon :icon="['fas', 'spinner']" class="text-primary" size="xl" spin />
  </div>
</template>

<script>
  export default {
    name: 'ResetPassword',
    mounted() {
      if (this.$route.query.app) {
        let url = `${this.$route.query.app}`;

        window.location.replace(url);

        setTimeout(() => {
          window.location.replace('https://www.peddle.store');
        }, 2000);
      }
    }
  };
</script>

<style scoped></style>
